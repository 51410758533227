const variables = {
  text: 'Text'
};

const variableIcons = {
  text: 'mdi-text-shadow'
};

const variableColors = {
  text: 'orange'
};

const getVariableIcon = function(variableType) {
  switch (variableType) {
    case variables.text:
      return variableIcons.text;
  }
};

const getVariableColor = function(variableType) {
  switch (variableType) {
    case variables.text:
      return variableColors.text;
  }
};

const supportedFocusModeVariableProperties = {
  [variables.text]: [
    'data.styles.fontSize',
    'data.styles.autofitFontSize',
    'data.styles.scaledFontSize',
    'data.styles.rotateAngle',
    'data.styles.opacity'
  ]
};

const getSupportedVariableProperties = [
  'fill',
  'fontSize',
  'fontFamily',
  'fontWeight',
  'fontStyle',
  'stroke',
  'strokeWidth'
];

module.exports = {
  variables,
  variableIcons,
  variableColors,
  getVariableIcon,
  getVariableColor,
  supportedFocusModeVariableProperties,
  getSupportedVariableProperties
};
