import api from 'Api';

export class HqLocalApiService {
  constructor(businessProfileId){
    this.businessProfileId = businessProfileId;
  }
  async getStoreConfiguration(storeId, promotionId, creativeJson, searchEngineId) {
    let parameters = {
      storeId,
      creativeJson,
      searchEngineId
    }
    let response = await api.post(`/businessProfiles/${this.businessProfileId}/promotions/${promotionId}/storeConfiguration`,  parameters);
    return response.data.data;
  }

  async generatePreviews(creatives, bpseId) {
    let response = await api.post(`/businessProfiles/${this.businessProfileId}/accounts/${bpseId}/creativePreviews`, { creatives });
    return  response.data.data;
  }

  async getSettingsAsync(keyprefixes) {
    let response = await api.get(`businessProfiles/${this.businessProfileId}/settings?keyprefixes=${keyprefixes}&includeInternal=true`);
    return response.data.data;
  }

  async getAllPromotions(parameters) {
    let response = await api.get(`/businessProfiles/${this.businessProfileId}/promotions`, { params: parameters });
    return response.data.data;
  }

  async getStores(userId) {
    let response = await api.get(`/businessProfiles/${this.businessProfileId}/stores?userId=${userId}`)
    return response.data.data;
  }

  async getDefaultStores(){
    let params = {}
    params.isDefault = true;
    let response = await api.get(`businessProfiles/${this.businessProfileId}/Stores`, { params: params  });
    return response.data.data[0];
  }

  async getLabels(storeDetails) {
    let filters = [
      {
        Field: 'Name',
        Operator: 7,
        Values: storeDetails
      }
    ]
    let response = await api.get(`/businessProfiles/${this.businessProfileId}/labels`, { params: {
      filters
    } })
    return response.data.labels;
  }

  async getTargetCluster(targetClusterIds, storeIds) {
    let response = await api.get(`/businessProfiles/${this.businessProfileId}/TargetClusters`, {
      params: {
        'ids': targetClusterIds,
        'storeIds': storeIds
      }
    })
    return response.data.data;
  }
  async launchPromotionCampaign(promotionCampaign){
    await api.post(`businessProfiles/${this.businessProfileId}/promotions/${promotionCampaign.promotion.id}/launch`, promotionCampaign)
  }

  async getVideos(bpseId, isUser) {
    let response = await api.get(`/businessProfiles/${this.businessProfileId}/accounts/${bpseId}/Videos`, {
      params: {
        'user': isUser
      }
    })
    return response.data.data;
  }

  async getImages(bpseId, isUser = null, imageIds = null) {
    let params = {};
    if (imageIds)
    {
      params['Ids'] = imageIds.join(',');
    }
    if (isUser)
    {
      params['user'] = isUser;
    }
    let response = await api.get(`/businessProfiles/${this.businessProfileId}/accounts/${bpseId}/Images`, { params })
    return response.data.data;
  }

  async uploadVideo (bpseId, file, pageId, userId, contentRange, fileName, sessionKey, additionalFacebookPageIds = []) {
    let formData = new FormData();
    formData.append('videoFile', file, fileName);
    formData.append('facebookPageId', pageId);
    formData.append('userId', userId);
    for(let i = 0; i < additionalFacebookPageIds.length; i++){
      formData.append(`additionalFacebookPageIds[${i}]`, additionalFacebookPageIds[i])
    }
    if (sessionKey) {
      formData.append('sessionKey', sessionKey);
    }
    let headers = { 'Content-Type': 'multipart/form-data' }
    if (contentRange) {
      headers['Content-Range'] = contentRange;
    }

    var response = await api.post(`businessProfiles/${this.businessProfileId}/accounts/${bpseId}/videos/upload`, formData, {
      headers: headers
    });

    return response.data.data;
  }

  async getPublishedPosts(pageId, bpseId){
    var response =  await api.get(`businessProfiles/${this.businessProfileId}/accounts/${bpseId}/live/pages/${pageId}/posts`);
    return response.data.data;
  }

  async getCanvases(pageId, bpseId){
    var response =  await api.get(`businessProfiles/${this.businessProfileId}/accounts/${bpseId}/live/pages/${pageId}/canvases`);
    return response.data.data;
  }

  async uploadImage(file, bpseId, userId) {
    let formData = new FormData();
    formData.append('imageFiles', file);
    formData.append('userId', userId);

    let response = await api.post(`/businessProfiles/${this.businessProfileId}/accounts/${bpseId}/Images/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return response.data.data;
  }

  async getVideosByIds(bpseId, videoIds){
    var response = await api.get(`businessProfiles/${this.businessProfileId}/accounts/${bpseId}/videos`, {
      params: {
        ids: videoIds
      }
    });
    return response.data.data;
  }

  async getLatestPromotionVersions(promotionIds)
  {
    var response = await api.get(`businessProfiles/${this.businessProfileId}/promotionVersions?promotionIds=${promotionIds.join(',')}&latest=true`);
    return response.data;
  }

  async getPromotion(promotionId){
    var response = await api.get(`businessProfiles/${this.businessProfileId}/promotions/${promotionId}`);
    return response.data.data;
  }
}

