const { levels } = require('../constants/textStyles');

const minTime = 0;
const maxTime = 120;
const minimumDuration = 0.1;
const direction = { forward: 'forward', backward: 'backward' };
const timings = { startTime: 'startTime', endTime: 'endTime' };
const minimumPositionWidth = 1;
const lineSegmentsCount = 10; // 10 hatch marks for each second on ruler.
const adSwitchWait = 500; // In milli sec
const MIN_TWEEN_DURATION = 0;
const TWEEN_START_VALUE = 0;
const TWEEN_END_VALUE = 1;
const lastResetWait = 20;
const decreaseLetterLevelDuration = 30;
const defaultFps = 30;
const defaultAudioBitrate = 128; // kb / s

const loopTypes = {
  once: 1,
  forever: 100
}
const foreverLoop = Infinity;

const download = {
  minOpacityChange: 0.001
}

const intensityConstants = {
  move: {
    low: 200,
    medium: 100,
    hard: 70,
    intense: 30
  }
}

const middleAnimationIntensities = {
  move: [
    {
      name: 'message.low',
      value: intensityConstants.move.low
    },
    {
      name: 'message.medium',
      value: intensityConstants.move.medium
    },
    {
      name: 'message.hard',
      value: intensityConstants.move.hard
    },
    {
      name: 'message.intense',
      value: intensityConstants.move.intense
    }
  ]
}

const animateConstants = {
  rotateByDegrees: '90',
  scaleStart: '0',
  scaleEnd: '1',
  rotateStart: '0',
  rotateEnd: '360',
  rotations: 1,
  spinDeg: '360',
  slideLength: '20',
  minScale: '0',
  maxScale: '1.2',
  minOpacity: '0',
  maxOpacity: '1',
  moveLength: '10',
  defaultMiddleAnimationCount: 2,
  defaultMiddleTextAnimationCount: 1,
  middleAnimateDuration: 600,
  moveDuration: 100,
  fontSizeScale: 0.85,
  defaultEase: 'easeLinear',
  slideStart: '0',
  slideEnd: '100',
  shineColor: { rgba: { r: 236, g: 235, b: 228, a: 1 }, hex: '#ECEBE4' },
  repeat: 1,
  shineDirection: 'topLeftToBottomRight',
  shineStrokeWidth: 20,
  circularShineDirection: 'centerToEnd',
  circularShineStrokeWidth: 50
}

const typeNames = {
  fade: 'Fade',
  blink: 'Blink',
  move: 'Move',
  heartBeat: 'HeartBeat',
  pulsate: 'Pulsate',
  spin: 'Spin',
  slideHorizontal: 'Slide Horizontal',
  slideVertical: 'Slide Vertical',
  fadeSlideHorizontal: 'Fade Slide Horizontal',
  fadeSlideVertical: 'Fade Slide Vertical',
  rotate: 'Rotate',
  scale: 'Scale',
  typeWriter: 'TypeWriter',
  shine: 'Shine',
  circularShine: 'Circular Shine',
  none: 'None'
}

const positions = {
  start: 'start',
  middle: 'middle',
  end: 'end'
}

const animationLayerTypes = {
  static: 'static',
  dynamic: 'dynamic'
}

const starImage = '/img/icons/star.png';

const nonLayerAnimationTypes = {
  start: [
    { name: typeNames.fade },
    { name: typeNames.slideVertical },
    { name: typeNames.fadeSlideVertical },
    { name: typeNames.scale },
    { name: typeNames.typeWriter },
    { name: typeNames.none }
  ],
  middle: [
    { name: typeNames.blink },
    { name: typeNames.pulsate },
    { name: typeNames.heartBeat },
    { name: typeNames.none }
  ],
  end: [
    { name: typeNames.fade },
    { name: typeNames.slideVertical },
    { name: typeNames.fadeSlideVertical },
    { name: typeNames.scale },
    { name: typeNames.typeWriter },
    { name: typeNames.none }
  ]
}

const animationTypes = {
  [levels.layer]: {
    start: [
      { name: typeNames.fade },
      { name: typeNames.slideVertical },
      { name: typeNames.slideHorizontal },
      { name: typeNames.fadeSlideVertical },
      { name: typeNames.fadeSlideHorizontal },
      { name: typeNames.rotate },
      { name: typeNames.scale },
      { name: typeNames.shine },
      { name: typeNames.circularShine },
      { name: typeNames.none }
    ],
    middle: [
      { name: typeNames.blink },
      { name: typeNames.move },
      { name: typeNames.pulsate },
      { name: typeNames.heartBeat },
      { name: typeNames.spin },
      { name: typeNames.none }
    ],
    end: [
      { name: typeNames.fade },
      { name: typeNames.slideVertical },
      { name: typeNames.slideHorizontal },
      { name: typeNames.fadeSlideVertical },
      { name: typeNames.fadeSlideHorizontal },
      { name: typeNames.rotate },
      { name: typeNames.scale },
      { name: typeNames.shine },
      { name: typeNames.circularShine },
      { name: typeNames.none }
    ]
  },
  [levels.letter]: nonLayerAnimationTypes,
  [levels.line]: nonLayerAnimationTypes,
  [levels.word]: nonLayerAnimationTypes
}

const easingFunctions = [
  {
    fabricName: animateConstants.defaultEase,
    ease: 'Linear',
    type: 'None'
  },
  {
    fabricName: 'easeInQuad',
    ease: 'Quadratic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuad',
    ease: 'Quadratic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuad',
    ease: 'Quadratic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCubic',
    ease: 'Cubic',
    type: 'In'
  },
  {
    fabricName: 'easeOutCubic',
    ease: 'Cubic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCubic',
    ease: 'Cubic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInQuart',
    ease: 'Quartic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuart',
    ease: 'Quartic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuart',
    ease: 'Quartic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInQuint',
    ease: 'Quintic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuint',
    ease: 'Quintic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuint',
    ease: 'Quintic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInSine',
    ease: 'Sinusoidal',
    type: 'In'
  },
  {
    fabricName: 'easeOutSine',
    ease: 'Sinusoidal',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutSine',
    ease: 'Sinusoidal',
    type: 'InOut'
  },
  {
    fabricName: 'easeInExpo',
    ease: 'Exponential',
    type: 'In'
  },
  {
    fabricName: 'easeOutExpo',
    ease: 'Exponential',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutExpo',
    ease: 'Exponential',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCirc',
    ease: 'Circular',
    type: 'In'
  },
  {
    fabricName: 'easeOutCirc',
    ease: 'Circular',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCirc',
    ease: 'Circular',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCubic',
    ease: 'Cubic',
    type: 'In'
  },
  {
    fabricName: 'easeOutCubic',
    ease: 'Cubic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCubic',
    ease: 'Cubic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInElastic',
    ease: 'Elastic',
    type: 'In'
  },
  {
    fabricName: 'easeOutElastic',
    ease: 'Elastic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutElastic',
    ease: 'Elastic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInBack',
    ease: 'Back',
    type: 'In'
  },
  {
    fabricName: 'easeOutBack',
    ease: 'Back',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutBack',
    ease: 'Back',
    type: 'InOut'
  },
  {
    fabricName: 'easeInBounce',
    ease: 'Bounce',
    type: 'In'
  },
  {
    fabricName: 'easeOutBounce',
    ease: 'Bounce',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutBounce',
    ease: 'Bounce',
    type: 'InOut'
  }
]

const resetProperties = ['top', 'left', 'opacity', 'scaleY', 'scaleX', 'originX', 'originY', 'angle', 'visible', 'styles', 'padding', 'text', 'currentTime', 'fill'];

const animationPlayModes = {
  playAll: 'playAll',
  playSelected: 'playSelected',
  playEach: 'playEach'
}

const stylesProperties = {
  fill: 'fill',
  deltaY: 'deltaY',
  fontSize: 'fontSize',
  top: 'top',
  left: 'left'
}

const animationUpdateTypes = {
  LAYER: 'layer',
  ANIMATION_POSITION: 'animationPosition',
  ANIMATION_SOURCE_POSITION: 'animationSourcePosition'
}

const animationConfigsRange = {
  fade: {
    min: '0',
    max: '100'
  },
  rotations: {
    min: '0',
    max: '50'
  },
  scale: {
    min: '0',
    max: '2000'
  },
  angle: {
    min: '-360',
    max: '360'
  },
  distance: {
    min: '0',
    max: '200'
  }
}

const currentAnimationConfigValue = 'Current';

const animationConfigInitialValues = {
  fade: {
    fadeStart: '0',
    fadeEnd: currentAnimationConfigValue
  },
  scale: {
    scaleStart: '0',
    scaleEnd: currentAnimationConfigValue
  },
  slide: {
    slideStart: '0',
    slideEnd: currentAnimationConfigValue
  },
  fadeSlide: {
    slideStart: '0',
    slideEnd: currentAnimationConfigValue,
    fadeStart: '0',
    fadeEnd: currentAnimationConfigValue
  },
  rotate: {
    angleStart: '0',
    angleEnd: '360',
    rotations: 1,
    retrace: false
  },
  heartbeat: {
    count: animateConstants.defaultMiddleAnimationCount
  },
  spin: {
    count: animateConstants.defaultMiddleAnimationCount
  },
  blink: {
    count: animateConstants.defaultMiddleAnimationCount
  },
  move: {
    intensity: intensityConstants.move.medium,
    distance: '10'
  },
  pulsate: {
    fadeStart: currentAnimationConfigValue,
    fadeEnd: '0',
    scaleStart: currentAnimationConfigValue,
    scaleEnd: '0',
    count: animateConstants.defaultMiddleAnimationCount
  },
  shine: {
    color: animateConstants.shineColor,
    strokeWidth: animateConstants.shineStrokeWidth,
    direction: animateConstants.shineDirection,
    repeat: animateConstants.repeat
  },
  circularShine: {
    color: animateConstants.shineColor,
    strokeWidth: animateConstants.circularShineStrokeWidth,
    direction: animateConstants.circularShineDirection,
    repeat: animateConstants.repeat
  }
}

const textAnimationConfigInitialValues = {
  fade: {
    fadeStart: animateConstants.minOpacity,
    fadeEnd: currentAnimationConfigValue
  },
  scale: {
    scaleStart: animateConstants.scaleStart,
    scaleEnd: currentAnimationConfigValue
  },
  slide: {
    slideStart: animateConstants.slideStart,
    slideEnd: currentAnimationConfigValue
  },
  fadeSlide: {
    slideStart: animateConstants.slideStart,
    slideEnd: currentAnimationConfigValue,
    fadeStart: animateConstants.minOpacity,
    fadeEnd: currentAnimationConfigValue
  },
  heartbeat: {
    count: animateConstants.defaultMiddleTextAnimationCount
  },
  blink: {
    count: animateConstants.defaultMiddleTextAnimationCount
  },
  pulsate: {
    fadeStart: currentAnimationConfigValue,
    fadeEnd: animateConstants.minOpacity,
    scaleStart: currentAnimationConfigValue,
    scaleEnd: animateConstants.scaleStart,
    count: animateConstants.defaultMiddleTextAnimationCount
  }
}

const startAndEndAnimationPreviewContants = {
  'Fade': {
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Scale': {
    scaleStart: animateConstants.scaleStart.toString(),
    scaleEnd: (animateConstants.scaleEnd * 100).toString()
  },
  'Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString()
  },
  'Slide Horizontal': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString()
  },
  'Fade Slide Horizontal': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString(),
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Fade Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString(),
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Rotate': {
    angleStart: animateConstants.rotateStart.toString(),
    angleEnd: animateConstants.rotateEnd.toString(),
    rotations: animateConstants.rotations,
    retrace: false
  },
  'Shine': {
    color: animateConstants.shineColor,
    strokeWidth: animateConstants.shineStrokeWidth,
    direction: animateConstants.shineDirection,
    repeat: animateConstants.repeat
  },
  'Circular Shine': {
    color: animateConstants.shineColor,
    strokeWidth: animateConstants.circularShineStrokeWidth,
    direction: animateConstants.circularShineDirection,
    repeat: animateConstants.repeat
  }
}

const animationPreviewConstants = {
  start: startAndEndAnimationPreviewContants,
  middle: {
    'Blink': {
      count: animateConstants.defaultMiddleAnimationCount
    },
    'Move': {
      intensity: animateConstants.moveDuration,
      distance: animateConstants.moveLength.toString()
    },
    'Spin': {
      count: animateConstants.defaultMiddleAnimationCount
    },
    'HeartBeat': {
      count: animateConstants.defaultMiddleAnimationCount
    },
    'Pulsate': {
      fadeStart: animateConstants.minOpacity.toString(),
      fadeEnd: (animateConstants.maxOpacity * 100).toString(),
      scaleStart: animateConstants.scaleStart.toString(),
      scaleEnd: (animateConstants.scaleEnd * 100).toString(),
      count: animateConstants.defaultMiddleAnimationCount
    }
  },
  end: startAndEndAnimationPreviewContants
}

const startAndEndTextAnimationPreviewContants = {
  'Fade': {
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Scale': {
    scaleStart: animateConstants.scaleStart.toString(),
    scaleEnd: (animateConstants.scaleEnd * 100).toString()
  },
  'Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: currentAnimationConfigValue
  },
  'Fade Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: currentAnimationConfigValue,
    fadeStart: (animateConstants.minOpacity * 100).toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  }
}

const textAnimationPreviewConstants = {
  start: startAndEndTextAnimationPreviewContants,
  middle: {
    'Blink': {
      count: animateConstants.defaultMiddleTextAnimationCount
    },
    'HeartBeat': {
      count: animateConstants.defaultMiddleTextAnimationCount
    },
    'Pulsate': {
      fadeStart: animateConstants.minOpacity.toString(),
      fadeEnd: (animateConstants.maxOpacity * 100).toString(),
      scaleStart: currentAnimationConfigValue,
      scaleEnd: animateConstants.scaleStart.toString(),
      count: animateConstants.defaultMiddleTextAnimationCount
    }
  },
  end: startAndEndTextAnimationPreviewContants
}

const nonSupportedAnimationTypesForTextLevel = [typeNames.move, typeNames.spin, typeNames.slideHorizontal, typeNames.fadeSlideHorizontal, typeNames.rotate]

const shineAnimationDirection = {
  FORWARD: 'forward',
  BACKWARD: 'backward',
  DOWN: 'down',
  UP: 'up',
  TOP_LEFT_TO_BOTTOM_RIGHT: 'topLeftToBottomRight',
  TOP_RIGHT_TO_BOTTOM_LEFT: 'topRightToBottomLeft',
  BOTTOM_LEFT_TO_TOP_RIGHT: 'bottomLeftToTopRight',
  BOTTOM_RIGHT_TO_TOP_LEFT: 'bottomRightToTopLeft'
};

const circularShineAnimationDirection = {
  CENTER_TO_END: 'centerToEnd',
  END_TO_CENTER: 'endToCenter'
};

const existingTemplatesConstants = {
  pingFadeStart: '30',
  fadeStart: '0',
  zoomInScaleEnd: '120',
  scaleDownScaleStart: '120',
  pulsateScaleEnd: '120',
  layerPulsateScaleEnd: '125',
  pingScaleEnd: '0',
  scaleUpScaleEnd: '120',
  ScaleDownScaleEnd: '0',
  fadeEnd: '0'
}

module.exports = {
  minTime,
  maxTime,
  minimumDuration,
  direction,
  timings,
  minimumPositionWidth,
  lineSegmentsCount,
  adSwitchWait,
  MIN_TWEEN_DURATION,
  TWEEN_START_VALUE,
  TWEEN_END_VALUE,
  lastResetWait,
  decreaseLetterLevelDuration,
  defaultFps,
  defaultAudioBitrate,
  loopTypes,
  foreverLoop,
  download,
  middleAnimationIntensities,
  animateConstants,
  typeNames,
  positions,
  animationLayerTypes,
  starImage,
  animationTypes,
  easingFunctions,
  resetProperties,
  animationPlayModes,
  stylesProperties,
  animationUpdateTypes,
  animationConfigsRange,
  currentAnimationConfigValue,
  animationConfigInitialValues,
  textAnimationConfigInitialValues,
  animationPreviewConstants,
  textAnimationPreviewConstants,
  nonSupportedAnimationTypesForTextLevel,
  shineAnimationDirection,
  circularShineAnimationDirection,
  existingTemplatesConstants
};