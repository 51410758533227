const layers = {
  background: 'Background',
  text: 'Text',
  button: 'Button',
  image: 'Image',
  border: 'Border',
  shape: 'Shape',
  subtitle: 'Subtitle',
  video: 'Video',
  audio: 'Audio',
  speakerImage: 'SpeakerImage',
  multipleImages: 'MultipleImages',
  safeArea: 'SafeArea'
}

const layerIcons = {
  background: 'wallpaper',
  text: 'text_fields',
  button: 'add_box',
  image: 'add_photo_alternate',
  border: 'border_outer',
  shape: 'category',
  subtitle: 'subtitles',
  video: 'videocam',
  audio: 'audiotrack',
  speakerImage: 'add_photo_alternate',
  multipleImages: 'perm_media',
  safeArea: 'texture_box'
}

const layerColors = {
  background: 'blue',
  text: 'pink',
  button: 'green',
  image: 'purple',
  border: 'lime',
  shape: 'yellow',
  video: 'teal',
  audio: 'indigo'
};

const getLayerIcon = function(layerType) {
  switch (layerType) {
    case layers.background:
      return layerIcons.background;
    case layers.text:
      return layerIcons.text;
    case layers.button:
      return layerIcons.button;
    case layers.image:
      return layerIcons.image;
    case layers.border:
      return layerIcons.border;
    case layers.shape:
      return layerIcons.shape;
    case layers.subtitle:
      return layerIcons.subtitle;
    case layers.video:
      return layerIcons.video;
    case layers.audio:
      return layerIcons.audio;
    case layers.speakerImage:
      return layerIcons.speakerImage;
    case layers.multipleImages:
      return layerIcons.multipleImages;
    case layers.safeArea:
      return layerIcons.safeArea;
  }
};
const getLayerColor = function(layerType) {
  switch (layerType) {
    case layers.background:
      return layerColors.background;
    case layers.text:
      return layerColors.text;
    case layers.button:
      return layerColors.button;
    case layers.image:
      return layerColors.image;
    case layers.border:
      return layerColors.border;
    case layers.shape:
      return layerColors.shape;
    case layers.video:
      return layerColors.video;
    case layers.audio:
      return layerColors.audio;
  }
};

const reorderActions = {
  sendBackwards: 'sendObjectBackwards',
  sendToBack: 'sendObjectToBack',
  bringForward: 'bringObjectForward',
  bringToFront: 'bringObjectToFront',
  dragAndDrop: 'dragAndDrop'
};

const layerReorderDisabledLayers = [
  layers.background,
  layers.border,
  layers.subtitle,
  layers.speakerImage,
  layers.safeArea
];

const layerCloneDisabledLayers = [
  layers.background,
  layers.border,
  layers.subtitle,
  layers.audio,
  layers.speakerImage,
  layers.safeArea
];

const layerLockDisabledLayers = [
  layers.background,
  layers.border,
  layers.safeArea
];

const layerHideDisabledLayers = [];

const mediaTypes = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  font: 'font'
};

const supportedMediaExtensions = {
  image: ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif'],
  video: ['mp4', 'webm', 'ogv'],
  audio: ['mp3', 'wav', 'ogg'],
  font: ['ttf', 'otf', 'woff', 'woff2']
};

const supportedFocusModeProperties = {
  [layers.text]: [
    'data.adLayoutPositions',
    'data.hidden',
    'data.styles.fontSize',
    'data.styles.autofitFontSize',
    'data.styles.inFocusMode',
    'data.styles.width',
    'data.styles.scaledFontSize',
    'data.styles.textAlign',
    'data.styles.maxLines',
    'data.styles.rotateAngle',
    'data.styles.opacity',
    'data.styles.autofit'
  ],
  [layers.button]: [
    'data.adLayoutPositions',
    'data.hidden',
    'data.text.styles.fontSize',
    'data.text.styles.isInFocusMode',
    'data.text.styles.scaledFontSize',
    'data.text.styles.canvasRelativeFontSize',
    'data.text.styles.textAlign',
    'data.text.styles.isTextLeftAligned',
    'data.text.styles.width',
    'data.text.styles.height',
    'data.boundingRectangle.scaledHeight',
    'data.boundingRectangle.scaledWidth',
    'data.boundingRectangle.buttonHeight',
    'data.boundingRectangle.buttonWidth',
    'data.boundingRectangle.canvasRelativeHeight',
    'data.boundingRectangle.canvasRelativeWidth',
    'data.boundingRectangle.rxCornerRadius',
    'data.boundingRectangle.ryCornerRadius',
    'data.boundingRectangle.angle',
    'data.text.styles.autofit',
    'data.text.styles.autofitFontSize',
    'data.text.styles.maxLines'
  ],
  [layers.image]: [
    'data.adLayoutPositions',
    'data.hidden',
    'data.props',
    'data.filters',
    'data.styles.opacity',
    'data.styles.scaleX',
    'data.styles.scaleY',
    'data.styles.defaultScaleX',
    'data.styles.defaultScaleY',
    'data.styles.selectedFit',
    'data.styles.angle',
    'data.styles.rotateAngle'
  ],
  [layers.shape]: [
    'data.adLayoutPositions',
    'data.hidden',
    'data.styles.opacity',
    'data.styles.scaleX',
    'data.styles.scaleY',
    'data.styles.angle',
    'data.styles.top',
    'data.styles.left',
    'data.styles.path',
    'data.styles.height',
    'data.styles.width',
    'data.styles.radius',
    'data.styles.rx',
    'data.styles.ry',
    'data.styles.type'
  ],
  [layers.video]: [
    'data.adLayoutPositions',
    'data.hidden',
    'data.props',
    'data.styles.opacity',
    'data.styles.scaleX',
    'data.styles.scaleY',
    'data.styles.angle'
  ],
  [layers.audio]: [
    'data.adLayoutPositions',
    'data.props'
  ],
  [layers.background]: [
    'data.adLayoutPositions',
    'data.hidden',
    'data.imageProps'
  ],
  [layers.border]: [
    'data.adLayoutPositions',
    'data.hidden'
  ],
  [layers.safeArea]: [
    'data.hidden',
    'data.overlay',
    'data.styles.opacity',
    'data.padding'
  ]
};

const layerColorProperties = {
  fill: 'fill',
  backgroundColor: 'background-color',
  stroke: 'stroke'
}

module.exports = {
  layers,
  layerIcons,
  layerColors,
  getLayerIcon,
  getLayerColor,
  reorderActions,
  layerReorderDisabledLayers,
  layerCloneDisabledLayers,
  layerLockDisabledLayers,
  layerHideDisabledLayers,
  mediaTypes,
  supportedMediaExtensions,
  supportedFocusModeProperties,
  layerColorProperties
};