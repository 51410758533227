const { deepAssign } = require('./commonHelper');
const { mediaElementReadyState } = require('../constants/audio');
const { FabricImage } = require('fabric');
const { setLayoutPosition } = require('./gridLayoutHelper');

const getImageDimensions = (data) => {
  let width, height;
  if (!data.multipleSources || data.props.sources.length == 0) {
    width = data.props.width;
    height = data.props.height;
  }
  else {
    width = data.props.sources[0].width;
    height = data.props.sources[0].height;
  }
  return { width, height };
}

const updateAudioLayer = (layerToUpdate, data) => {
  deepAssign(layerToUpdate.data, data);
  //Clone array because layerToUpdate.data.adLayoutPositions and data.adLayoutPositions have the same reference
  if(data.adLayoutPositions){
    let clone = data.adLayoutPositions.slice(0);
    // avoiding changing array reference and simply emptying it and pushing the new items
    // This is done so that reactivity is not lost
    layerToUpdate.data.adLayoutPositions.length = 0;
    layerToUpdate.data.adLayoutPositions.push(...clone);
  }
}

const loadMediaElement = async function(mediaElement) {
  let mediaLoadPromise = new Promise((resolve, reject) => {
    mediaElement.load();
    let mediaElementReadyStateTimeout = null;
    // Below function adapted from http://atomicrobotdesign.com/blog/web-development/check-when-an-html5-video-has-loaded/
    function checkLoad() {
      if (mediaElement.readyState === mediaElementReadyState.HAVE_ENOUGH_DATA) {
        clearTimeout(mediaElementReadyStateTimeout)
        resolve();
      }
      else {
        mediaElementReadyStateTimeout = setTimeout(checkLoad, 100);
      }
    }
    checkLoad();
  });
  return mediaLoadPromise;
}

const createVideoFabricObject = (ad, videoElement, layer) => {
  let fabricObject = new FabricImage(videoElement, {
    visible: !layer.data.hidden,
    objectCaching: false,
    ...layer.data.styles
  },
  {
    crossOrigin: 'Anonymous'
  });
  setLayoutPosition(ad, fabricObject, layer.data);
  fabricObject.layerId = layer.id;
  return fabricObject;
}

module.exports = {
  getImageDimensions,
  updateAudioLayer,
  loadMediaElement,
  createVideoFabricObject
};