const offlineFontInfo = require('../customFonts.json');
const customPublishApiFontInfo = require('../customPublishApiFonts.json');
const standardPublishApiFontInfo = require('../standardPublishApiFonts.json');

const fontStyles = {
  Bold: 1,
  Italic: 2,
  Underlined: 3,
  Overlined: 4,
  Strikedthrough: 5
}
const standardFonts = [
  {
    'name': 'Arial',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Arial Black',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Comic Sans MS',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Impact',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Charcoal',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Lucida Sans Unicode',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Lucida Grande',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Tahoma',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Trebuchet MS',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Verdana',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Courier New',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Lucida Console',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Palatino Linotype',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  },
  {
    'name': 'Book Antiqua',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  },
  {
    'name': 'Georgia',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  },
  {
    'name': 'Times New Roman',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  }
]
const fontInfo = offlineFontInfo.concat(standardFonts);
const fontFamilies = fontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
const offlineFontFamilies = offlineFontInfo.map(font => font.name).sort();
const standardFontFamilies = standardFonts.map(font => font.name).sort();
const availablePublishApiFontInfo = standardFonts.concat(standardPublishApiFontInfo);
const customPublishApiFontFamilies = customPublishApiFontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
const availablePublishApiFontFamilies = availablePublishApiFontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
const publishApiFontInfo = customPublishApiFontInfo.concat(availablePublishApiFontFamilies);
const publishApiFontFamilies = publishApiFontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
const textBoxPaddingOffset = 0.01;
const textBoxRightProximityFactor = 0.98;
const customFontData = offlineFontInfo.map(font => {
  let variantsInFvdNotation =
    font.variants
      .map(variant => [`n${variant.value.toString()[0]}`, `i${variant.value.toString()[0]}`])
      .reduce((acc, val) => acc.concat(val), []); // Alternative for flat()
  return {
    name: font.name,
    fvdNotation: `${font.name}:${variantsInFvdNotation.join(',')}`
  };
})

const canvasRelativeTextSizes = {
  Heading: parseFloat(26 / 62500),
  Subheading: parseFloat(20 / 62500),
  Body: parseFloat(18 / 62500)
}

const fontCases = {
  Lower: 1,
  Upper: 2,
  Capitalized: 3
}

const scripts = {
  Subscripted: 1,
  Superscripted: 2
}

const defaultTextBackgroundOptions = {
  padding: {
    top: 10,
    right: 10,
    bottom: 8,
    left: 10
  },
  cornerRadius: {
    topLeft: 0,
    topRight: 0,
    bottomLeft: 0,
    bottomRight: 0
  },
  skew: {
    skewX: 0,
    skewY: 0
  }
}

const levels = {
  layer: 'layer',
  letter: 'letter',
  word: 'word',
  line: 'line'
}

const textAnimationLevels = [levels.letter, levels.word, levels.line];

const fontWeightMappings = {
  thin: 100,
  'ultra light': 200,
  'extra light': 200,
  light: 300,
  regular: 400,
  medium: 500,
  'demi bold': 600,
  'semi bold': 600,
  bold: 700,
  'extra bold': 800,
  'super bold': 800,
  'ultra bold': 800,
  black: 900,
  heavy: 900
}

const fontWeightNameMapping = {
  100: 'Hairline',
  200: 'Ultra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Black'
};

const fontStyleMappings = {
  italic: 'italic',
  oblique: 'oblique',
  normal: 'normal',
  regular: 'normal'
}

module.exports = {
  fontStyles,
  standardFonts,
  fontInfo,
  fontFamilies,
  offlineFontFamilies,
  standardFontFamilies,
  availablePublishApiFontInfo,
  customPublishApiFontFamilies,
  availablePublishApiFontFamilies,
  publishApiFontInfo,
  publishApiFontFamilies,
  textBoxPaddingOffset,
  textBoxRightProximityFactor,
  customFontData,
  canvasRelativeTextSizes,
  fontCases,
  scripts,
  defaultTextBackgroundOptions,
  levels,
  textAnimationLevels,
  fontWeightMappings,
  fontWeightNameMapping,
  fontStyleMappings
};